.inventroryActionIcons {
    position: fixed;
    margin-top: -45px;
    right: 14px;
}

.inventroryActionIcons button {
    margin-right: 10px;
}

.inventroryActionIcons button {
    border: none;
    padding: 4px 10px;
    border-radius: 0.26rem;
}

.inventroryActionIcons button:active,
.inventroryActionIcons button.active {
    color: #104dba;
}

.card-widget {
    display: flex;
    align-items: flex-start;
}

.card-widget img {
    margin-right: 10px;
}

.card-widget h4 {
    font-weight: 600;
    font-size: 24px;
    display: flex;
    align-items: baseline;
    margin: 0;
}

.card-widget p {
    display: block;
    margin-left: 13px;
}

.card-widget p img {
    margin: 0;
}

.card-widget span {
    color: #bdc2d5;
    font-weight: 400;
    font-size: 13px;
}

.ant-card .ant-card-body {
    padding: 20px;
}

.ant-card {
    border-radius: 0.27rem !important;
    box-shadow: 1px 1px 4px #d9d9d9;
}

.dashboardheader {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.dashboardheader h3 {
    font-size: 16px;
}

.dashboardheader div button {
    border: none;
    padding: 4px 11px;
    margin-left: 11px;
    border-radius: 0.26rem;
    font-size: 12px;
}

.dashboardheader div button:visited {
    background-color: #ecf0ff;
    color: #5673e5;
    font-weight: 500;
}

.dashboardheader div button:active,
.dashboardheader div button.active {
    font-weight: 600;
}